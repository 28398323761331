header {
  padding: 12px $padding_x 0;
  margin: 0 auto;
  text-align: center;
  .logo {
    width: 160px;
    display: inline-block;
    margin: 32px 0;
    img {
      width: 160px;
    }
    svg {
      .prefix__cls-2 {
        fill: $black;
      }
    }
  }
}
