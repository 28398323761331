button {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
}

.btn {
  border: none;
  background-color: $black;
  color: whitesmoke;
  max-width: 280px;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  min-width: 280px;
  cursor: pointer;
  color: lighten($nickel, 44%);
  transition: all 0.2s linear;
  margin-top: 8px;
  &:hover {
    background-color: lighten($black, 15%);
  }
}

a.btn {
  text-decoration: none;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  margin: 8px auto 0;
  align-items: center;
  align-self: center;
}

button:hover,
button:focus,
a:hover,
a:focus {
  background-color: $black;
}

button:focus,
a:focus {
  outline: none;
  outline-offset: 0;
}

button:active,
a:active {
  transform: scale(0.99);
}
